import React, { useState, useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../service/useApiClient';
import { createNote, createTasks, createTag } from '../api';
import { config } from '../Util';
import TagsComponent from './TagsComponent';
import styles from './styles/QuickAdd.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addTag } from '../../slices/tagsSlice';

interface Tag {
    tagId: string;
    name: string;
    color?: string;
    active?: boolean;
    variant?: string;
}

const QuickAdd: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [content, setContent] = useState('');
    const [type, setType] = useState<'note' | 'task'>('note');
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const queryClient = useQueryClient();
    const api = useApiClient();
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const reduxTags = useSelector((state: any) => state.tags.items);

    // Map Redux tags to the format we need, including active state
    const tagsWithActiveState = reduxTags.map((tag: Tag) => ({
        ...tag,
        active: selectedTags.some(selectedTag => selectedTag.tagId === tag.tagId)
    }));

    useEffect(() => {
        const handleGlobalKeyDown = (event: KeyboardEvent) => {
            // Toggle QuickAdd with Command + Shift + Space
            if (event.metaKey && event.shiftKey && event.code === 'Space') {
                event.preventDefault();
                setIsOpen(prev => !prev);
                if (!isOpen) {
                    // Focus the textarea when opening
                    setTimeout(() => {
                        const textarea = containerRef.current?.querySelector('textarea');
                        textarea?.focus();
                    }, 0);
                }
            }
        };

        // Add global keyboard listener
        document.addEventListener('keydown', handleGlobalKeyDown);
        
        return () => {
            document.removeEventListener('keydown', handleGlobalKeyDown);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Only close if clicking completely outside the container
            if (!containerRef.current?.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isOpen) return;

            if (event.metaKey && event.shiftKey && event.key === 't') {
                event.preventDefault(); // Prevent any default behavior
                setType(prevType => prevType === 'note' ? 'task' : 'note');
            }

            if (event.metaKey && event.key === 'Enter') {
                event.preventDefault();
                const submitButton = containerRef.current?.querySelector('button[type="submit"]') as HTMLButtonElement;
                submitButton?.click();
            }
        };

        if (isOpen) {
            // Use mousedown instead of click for better interaction
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!content.trim()) return;

        const tagIds = selectedTags.map(tag => tag.tagId);

        try {
            if (type === 'note') {
                const response = await createNote(api, content, tagIds);
                queryClient.setQueryData(config.notesQueryKey, (oldData: any[] = []) => [
                    ...oldData,
                    {
                        noteId: response.recordId,
                        content,
                        tags: selectedTags,
                        variant: "personal",
                        createdDate: new Date().toISOString()
                    }
                ]);
            } else {
                // Split content by newlines for tasks
                const contentArray = content.split('\n').filter(line => line.trim());
                const newTasksWithTagDetails = contentArray.map(name => ({ 
                    name, 
                    tags: selectedTags,
                    status: 'Active',
                    variant: "personal" 
                }));

                // Update local cache first
                queryClient.setQueryData(config.taskQueryKey, (oldData: any = {}) => ({
                    ...oldData,
                    activeTasks: [...(oldData.activeTasks || []), ...newTasksWithTagDetails]
                }));

                // Then create tasks on server
                await createTasks(api, contentArray, tagIds, 'Active');
            }
            setContent('');
            setSelectedTags([]);
            setIsOpen(false);
        } catch (error) {
            console.error('Error creating item:', error);
        }
    };

    const handleButtonToggle = (tagId: string) => {
        const tag = reduxTags.find((t: Tag) => t.tagId === tagId);
        if (tag) {
            const isSelected = selectedTags.some(t => t.tagId === tagId);
            if (isSelected) {
                setSelectedTags(selectedTags.filter(t => t.tagId !== tagId));
            } else {
                setSelectedTags([...selectedTags, tag]);
            }
        }
    };

    const handleEditableButtonUpdate = async (createTagObject: any) => {
        try {
            if (createTagObject === "") return;
            const newTags = await createTag(api, createTagObject);
            const updatedTags = newTags.data.map((tag: any) => ({
                tagId: tag.tagId,
                name: tag.name,
                color: tag.color,
                variant: "personal"
            }));
            
            // Update Redux store
            updatedTags.forEach((tag: Tag) => {
                dispatch(addTag(tag));
            });

            // Update query cache
            queryClient.setQueryData<Tag[]>(config.tagQueryKey, (oldTags = []) => [
                ...oldTags,
                ...updatedTags
            ]);
        } catch (error) {
            console.error('Error creating tag:', error);
        }
    };

    return (
        <div 
            ref={containerRef}
            className={styles.container}
            data-closed={!isOpen}
            onClick={() => !isOpen && setIsOpen(true)}
        >
            <div className={styles.contentWrapper}>
                <button 
                    className={styles.minimizeButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(!isOpen);
                    }}
                >
                    {isOpen ? '−' : '+'}
                </button>
                
                <div className={styles.contentContainer}>
                    <div className="py-4" style={{ display: isOpen ? 'block' : 'none' }}>
                        <form onSubmit={handleSubmit} className={styles.formContainer}>
                            <div className={styles.typeToggleContainer}>
                                <button
                                    type="button"
                                    onClick={() => setType('note')}
                                    className={`${styles.typeButton} ${type === 'note' ? styles.active : ''}`}
                                >
                                    Note
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setType('task')}
                                    className={`${styles.typeButton} ${type === 'task' ? styles.active : ''}`}
                                >
                                    Task
                                </button>
                            </div>
                            <div>
                                <textarea
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    placeholder={type === 'note' ? "Write a quick note..." : "Add a new task..."}
                                    className={styles.textarea}
                                    rows={6}
                                />
                            </div>
                            <div className="space-y-4">
                                <TagsComponent
                                    tags={tagsWithActiveState}
                                    handleButtonToggle={handleButtonToggle}
                                    handleEditableButtonUpdate={handleEditableButtonUpdate}
                                    includeEditButton={true}
                                />
                                <button
                                    type="submit"
                                    className="px-5 py-2 text-base bg-blue-50 text-blue-600 rounded-full hover:bg-blue-100 transition-colors"
                                >
                                    Add {type === 'note' ? 'Note' : 'Task'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickAdd;
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthButton = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    return (
        <button 
            className="login-logout" 
            onClick={() => 
                isAuthenticated 
                    ? logout({ logoutParams: { returnTo: window.location.origin } })
                    : loginWithRedirect()
            }
        >
            {isAuthenticated ? 'Log out' : 'Log in'}
        </button>
    );
};

export default AuthButton;

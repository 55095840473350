import React, { useEffect } from 'react';
import { Route, Routes, Outlet, Link } from 'react-router-dom';
import WritePage from './components/pages/WritePage';
import ReadPage from './components/pages/ReadPage';
import TrackingPage from './components/pages/TrackingPage';
import HabitsPage from './components/pages/HabitsPage';
import TagsPage from './components/pages/TagsPage';
import Navigation from './components/sections/menus/Navigation'
import SubMenu from './components/sections/menus/SubMenu';
import AdminPage from './components/pages/AdminPage';
import { setTags } from '../src/slices/tagsSlice';
import { useDispatch } from 'react-redux';
import SchedulePage from './components/pages/SchedulePage';
import { useQuery } from '@tanstack/react-query';
import Dashboard from './components/pages/Dashboard';
import AuthButton from './components/elements/auth/AuthButton';
import Profile from './components/elements/auth/Profile';
import { useAuth0 } from "@auth0/auth0-react";
import { fetchTags, createUser, getUser } from '../src/components/api';
import { useApiClient } from './service/useApiClient';
import QuickAdd from './components/elements/QuickAdd';

function App() {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();
  const api = useApiClient();

  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!isAuthenticated && !isLoading) {
        // Redirect to login if not authenticated
        await loginWithRedirect();
      } else if (isAuthenticated) {
        try {
          debugger;
          try {
            const user = await getUser(api) || {};
            if (!user.UserId) {
              await createUser(api);
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
          const token = await getAccessTokenSilently();
          console.log('Access token:', token);
        } catch (error) {
          console.error('Error retrieving access token:', error);
        }
      }
    };

    checkAuthentication();
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  const { data: tags, error, tagsAreLoading } = useQuery({
    queryKey: ['tags', { variant: 'personal' }],
    queryFn: async () => {
      return fetchTags(api);
    },
    enabled: isAuthenticated, // Ensure API call only happens when authenticated
    staleTime: 5 * 60 * 1000, // Keep tags fresh for 5 minutes
  });

  useEffect(() => {
    if (tags) {
      dispatch(setTags(tags));
    }
  }, [tags, dispatch]);

  if (tagsAreLoading) {
    return <div>Loading...</div>; // Show loading state while fetching
  }

  if (error) {
    return <div>Error loading tags</div>; // Handle error state
  }

  return (
    <div>
      {/* <h1>Life Management</h1> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="write" element={<WritePage />} />
          <Route path="read" element={<ReadPage />} />
          <Route path="track" element={<TrackingPage />} />

          <Route path="admin" element={<AdminPage />} >
            <Route path="schedule" element={<SchedulePage />} />
            <Route path="habits" element={<HabitsPage />} />
            <Route path="tags" element={<TagsPage />} />
          </Route>
          {/* <Route path="habits/admin" element={<HabitsPage />} />
            <Route path="habits/today" element={<TrackingPage />} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <div className="m-auto w-4/5">
        <div className="mt-10">
        </div>
        <Navigation />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="mb-2 text-md text-gray-600 pr-6">
          <AuthButton /> &nbsp;|&nbsp; <Profile />
        </div>
        <SubMenu />
        <hr />
        <br />
        <div className="bg-white rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
          <Outlet />
        </div>
      </div>
      <QuickAdd />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;